<template>
  <div>
    <el-dialog
      title="备注"
      :visible.sync="dialogFormVisible"
      width="500px"
      :close-on-click-modal="false"
      @closed="closeDia"
    >
      <el-form
        size="mini"
        label-width="80px"
        :model="handleFn"
        :rules="rules"
        ref="handleFn"
      >
        <el-form-item label="备注：">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            v-model="handleFn.remark"
            placeholder="请输入备注"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="closeDia">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { updateNote_api } from "@/apis/room";

export default {
  name: "Updateremarkdia",
  data() {
    return {
      dialogFormVisible: false,
      roomData: {},
      handleFn: {
        roomVestId: null,
        remark: null,
      },
      rules: {},
    };
  },
  methods: {
    open(room) {
      this.roomData = room;
      this.dialogFormVisible = true;
      this.handleFn.roomVestId = room.roomVestId;
      this.handleFn.remark = room.remark;
    },
    closeDia() {
      this.dialogFormVisible = false;
    },
    submitForm() {
      updateNote_api(this.handleFn).then((res) => {
        this.$message({ type: "success", message: "修改成功" });
        this.$parent.getRoomInfoById();
        this.dialogFormVisible = false;
      });
    },
    editRoomName() {
      editRoomName_api(this.handleFn).then((res) => {
        this.$message({ type: "success", message: "修改成功" });
        this.$parent.getRoomInfoById();
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
