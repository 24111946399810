<template>
  <div>
    <div class="orderInfoHead">
      <span>订单管理</span>>订单详情(订单号：{{
        orderDetailsInfoData.ordernum
      }}/渠道订单号：
      {{ orderDetailsInfoData.thirdOrderNum }}
      )
    </div>
    <div class="margintop30">
      <el-row>
        <el-col :span="12" class="orderBigNameBox">{{
          orderDetailsInfoData.hotelName
        }}</el-col>
        <el-col :span="12" class="textalignright">
          <el-button
            size="mini"
            type="primary"
            v-if="orderDetailsInfoData.isLock == 1"
            @click="setOrderLock(0)"
            plain
            >解锁</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-if="orderDetailsInfoData.isLock == 0"
            @click="setOrderLock(1)"
            plain
            >锁定</el-button
          >
          <el-button
            @click="goOrderPage"
            class="marginright30"
            size="mini"
            type="primary"
            plain
            >返回订单列表</el-button
          >
        </el-col>
      </el-row>
      <el-row class="margintop10 fontRuleOne">
        <el-col :span="23">
          <span>
            {{ orderDetailsInfoData.hotelName }}
          </span>
          (<span> 酒店地址：{{ orderDetailsInfoData.hotelAddress }} </span>
          <span> 酒店电话：{{ orderDetailsInfoData.hotelTel }} </span>
          )
        </el-col>
      </el-row>
      <el-row class="margintop10 fontRuleTwo">
        <el-col :span="23">
          <span class="marginright10">
            酒店负责人：{{ orderDetailsInfoData.hotelManager }}
          </span>
          <span class="marginright20">
            联系电话：{{ orderDetailsInfoData.hotelManagerTel }}
          </span>
          <span class="marginright10">
            产品经理：{{ orderDetailsInfoData.productManager }}
          </span>
          <span> 联系电话：{{ orderDetailsInfoData.productManagerTel }} </span>
        </el-col>
      </el-row>

      <div class="margintop30">
        <div class="width100 fontRuleThree">房型/间数/床型</div>
        <div class="width170 fontRuleOne">
          {{ orderDetailsInfoData.roomNumType }}
          <span class="colorone">{{ orderDetailsInfoData.fewRoom }}</span>
        </div>
        <div class="width170 fontRuleOne">
          房型ID：<span class="colorone">
            {{ orderDetailsInfoData.roomId }}</span
          >
          <span>
            {{ orderDetailsInfoData.roomType }}
          </span>
        </div>
      </div>
      <div class="margintop20">
        <div class="width100 fontRuleThree">入离日期</div>
        <div class="inlineblock fontRuleOne">
          <span>{{ orderDetailsInfoData.inAndOutTime }}</span>
          <span class="colorone">{{ orderDetailsInfoData.fewNights }}</span>
        </div>
      </div>
      <div class="margintop20">
        <div class="width100 fontRuleThree">入住人</div>
        <div class="inlineblock fontRuleOne">
          <span>{{ orderDetailsInfoData.inManAndSum }}</span>
          <span class="colorone">{{ orderDetailsInfoData.fewMan }}</span>
        </div>
      </div>
      <div class="margintop20">
        <div class="width100 fontRuleThree">最早到店时间</div>
        <div class="inlineblock fontRuleOne">
          <span>{{ orderDetailsInfoData.earlyArrivalTime }}</span>
        </div>
      </div>
      <div class="margintop20">
        <div class="width100 fontRuleThree">备注</div>
        <div class="inlineblock fontRuleOne">
          <span>{{ orderDetailsInfoData.remark }}</span>
        </div>
      </div>
    </div>
    <div class="margintop50">
      <div class="infoBox inlineblock marginright20">
        <div class="infoHeadBox">
          <div class="infoHeadLeft inlineblock" style="background: #f7a12e">
            销售方订单
          </div>
          <div
            class="inlineblock fontRuleOne"
            style="color: #ee4d52; margin-left: 40px"
          >
            {{ orderDetailsInfoData.orderStatus | formatStatus }}
          </div>
        </div>
        <div class="paddingleft20">
          <el-row class="margintop40">
            <el-col :span="4" class="fontRuleThree">订单号</el-col>
            <el-col :span="19">
              <span class="fontRuleOne">{{
                orderDetailsInfoData.thirdOrderNum
              }}</span>
            </el-col>
          </el-row>
          <el-row class="margintop30">
            <el-col :span="4" class="fontRuleThree">订单状态</el-col>
            <el-col :span="19">
              <span class="fontRuleOne">{{
                orderDetailsInfoData.orderStatus | formatStatus
              }}</span>
            </el-col>
          </el-row>
          <el-row class="margintop30">
            <el-col :span="4" class="fontRuleThree">分销方-渠道</el-col>
            <el-col :span="19" class="fontRuleOne">
              <span class="marginright20">{{
                orderDetailsInfoData.channel
              }}</span>
              <span class="colorone marginright10">联系方式:</span>
              <span>{{ orderDetailsInfoData.channelTel }}</span>
            </el-col>
          </el-row>
          <el-row class="margintop30">
            <el-col :span="4" class="fontRuleThree">总底价</el-col>
            <el-col :span="5">
              <span class="fontRuleFour"
                >CNY {{ orderDetailsInfoData.totalLowerPrice }}</span
              ><br />

              <span
                class="fontRuleOne"
                style="color: #f7a12e; line-height: 40px"
                >利润￥：{{ orderDetailsInfoData.profit }}</span
              >
            </el-col>
            <el-col :span="5" v-if="orderDetailsInfoData.couponMoney > 0">
              <span class="fontRuleFour">优惠券抵用金额 </span><br />

              <span
                class="fontRuleOne"
                style="color: #f7a12e; line-height: 40px"
                >￥：{{ orderDetailsInfoData.couponMoney }}</span
              >
            </el-col>
            <el-col :span="5" v-if="orderDetailsInfoData.walletMoney > 0">
              <span class="fontRuleFour">钱包抵用金额 </span><br />

              <span
                class="fontRuleOne"
                style="color: #f7a12e; line-height: 40px"
                >￥：{{ orderDetailsInfoData.walletMoney }}</span
              >
            </el-col>
            <el-col :span="5" v-if="orderDetailsInfoData.bargainMoney > 0">
              <span class="fontRuleFour">砍一刀抵用金额 </span><br />

              <span
                class="fontRuleOne"
                style="color: #f7a12e; line-height: 40px"
                >￥：{{ orderDetailsInfoData.bargainMoney }}</span
              >
            </el-col>
          </el-row>

          <el-row class="margintop30">
            <el-col :span="4" class="fontRuleThree">每日房价</el-col>
            <el-col :span="19">
              <div
                class="fangjiaBox inlineblock marginright10"
                v-for="(item, index) in orderDetailsInfoData.timeAndPriceVo"
                :key="index"
              >
                <div class="fangjiaBoxHead fontRuleThree">
                  {{ item.date }}
                </div>
                <div class="fangjiaBoxPrice">CNY {{ item.price }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row class="margintop30">
            <el-col :span="4" class="fontRuleThree">订单取消规则</el-col>
            <el-col :span="19">
              <span style="fontRuleOne">{{
                orderDetailsInfoData.orderCancelRule
              }}</span>
            </el-col>
          </el-row>
          <el-row class="margintop30">
            <el-col :span="4" class="fontRuleThree">备注</el-col>
            <el-col :span="19">
              <span class="fontRuleOne">{{ orderDetailsInfoData.remark }}</span>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="infoBox inlineblock">
        <div class="infoHeadBox">
          <div class="infoHeadLeft inlineblock" style="background: #256def">
            供应方订单
          </div>
        </div>
        <div class="paddingleft20">
          <el-row class="margintop40">
            <el-col :span="5" class="fontRuleThree">发单单号</el-col>
            <el-col :span="19">
              <span class="fontRuleOne">{{
                orderDetailsInfoData.ordernum
              }}</span>
            </el-col>
          </el-row>
          <el-row class="margintop30">
            <el-col :span="5" class="fontRuleThree">发单状态</el-col>
            <el-col :span="19">
              <span class="fontRuleOne">{{
                orderDetailsInfoData.orderStatusT
              }}</span>
            </el-col>
          </el-row>
          <el-row class="margintop30">
            <el-col :span="5" class="fontRuleThree">供应方</el-col>
            <el-col :span="19">
              <span class="fontRuleOne">{{
                orderDetailsInfoData.hotelName
              }}</span>
            </el-col>
          </el-row>
          <el-row class="margintop30">
            <el-col :span="5" class="fontRuleThree">总采购价</el-col>
            <el-col :span="8">
              <span class="fontRuleFour"
                >CNY {{ orderDetailsInfoData.totalPurchasePrice }}</span
              >
            </el-col>
            <el-col
              :span="8"
              v-if="orderDetailsInfoData.isHotelPay != '打款成功'"
            >
              <el-button
                size="small"
                type="text"
                @click="openSetOrderTotalPurchasePrice"
                >修改</el-button
              >
            </el-col>
          </el-row>

          <el-row class="margintop30">
            <el-col :span="5" class="fontRuleThree">每日采购价</el-col>
            <el-col :span="19">
              <div
                class="fangjiaBox inlineblock marginright10"
                v-for="(item, index) in orderDetailsInfoData.timeAndPriceVoT"
                :key="index"
              >
                <div class="fangjiaBoxHead fontRuleThree">
                  {{ item.date }}
                </div>
                <div class="fangjiaBoxPrice">CNY {{ item.price }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="margintop40">
      <el-row>
        <el-col :span="6">
          <span class="fontRuleFive">订单日志</span>
        </el-col>
        <el-col :span="18" style="text-align: right">
          <!-- <el-button size="mini" type="primary" style="margin-right: 30px"
            >服务申诉</el-button
          > -->
          <el-button
            size="mini"
            @click="openOrderLogDia(orderId)"
            type="primary"
            >新建事件</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="margintop30" style="margin-bottom: 50px">
      <el-table :data="orderDetailsInfoData.orderLog" style="width: 100%">
        <el-table-column prop="logId" align="center" label="编号" width="180" />
        <el-table-column
          prop="operationTime"
          align="center"
          label="操作时间"
          width="180"
        />
        <el-table-column
          prop="managerName"
          align="center"
          label="处理人"
          width="180"
        />
        <el-table-column prop="remarks" label="备注" />
      </el-table>
    </div>
    <AddOrderLog ref="addorderlog"></AddOrderLog>
    <SetOrderTotalPurchasePriceDia
      ref="setOrderTotalPurchasePrice"
    ></SetOrderTotalPurchasePriceDia>
  </div>
</template>

<script>
import { orderDetailsInfo_api } from "../../apis/order";
import { orderLock_api } from "../../apis/order";
import AddOrderLog from "../../components/dialog/order/AddOrderLog.vue";
import SetOrderTotalPurchasePriceDia from "../../components/dialog/order/SetOrderTotalPurchasePriceDia.vue";
export default {
  components: {
    AddOrderLog,
    SetOrderTotalPurchasePriceDia,
  },
  data() {
    return {
      orderDetailsInfoData: "",
      orderId: "",
    };
  },
  created() {
    this.orderId = this.$route.query.id;
    this.getDetailInfo(this.$route.query.id);
  },
  filters: {
    formatStatus(val) {
      if (val == 0) {
        return "未处理";
      } else if (val == 1) {
        return "已确认";
      } else if (val == 2) {
        return "确认前取消中";
      } else if (val == 3) {
        return "确认后取消中";
      } else if (val == 4) {
        return "确认前取消";
      } else if (val == 5) {
        return "确认后取消";
      } else if (val == 6) {
        return "超时拒绝";
      } else if (val == 7) {
        return "正常拒绝";
      } else if (val == 8) {
        return "保留房确认中";
      } else if (val == 9) {
        return "订单超时";
      }
    },
  },
  methods: {
    //获取页面数据
    async getDetailInfo(id) {
      let { code, data } = await orderDetailsInfo_api({ orderId: id });
      if (code == 0) {
        //  console.log(data);
        this.orderDetailsInfoData = data;
      }
    },

    //锁定/解锁订单
    async setOrderLock(val) {
      let { code, data } = await orderLock_api({
        orderId: this.orderId,
        isLock: val,
      });
      if (code == 0) {
        this.$message.success(data ? data : "成功");
        this.getDetailInfo(this.orderId);
      }
    },
    //打开备注模态框
    openOrderLogDia(id) {
      this.$refs.addorderlog.open(id);
    },
    openSetOrderTotalPurchasePrice() {
      this.$refs.setOrderTotalPurchasePrice.open(this.orderId);
    },
    goOrderPage() {
      this.$router.push({
        path: "/order",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.orderInfoHead {
  margin: top 20px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.orderBigNameBox {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.margintop30 {
  margin-top: 30px;
}
.textalignright {
  text-align: right;
}
.marginright30 {
  margin-right: 30px;
  margin-left: 40px;
}
.margintop10 {
  margin-top: 10px;
}
.marginright10 {
  margin-right: 10px;
}
.marginright20 {
  margin-right: 20px;
}
.fontRuleOne {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.fontRuleTwo {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.fontRuleThree {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.fontRuleFour {
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ee4d52;
}
.fontRuleFive {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.width100 {
  width: 110px;
  display: inline-block;
}
.width170 {
  width: 170px;
  display: inline-block;
}
.margintop20 {
  margin-top: 20px;
}
.inlineblock {
  display: inline-block;
}
.margintop50 {
  margin-top: 50px;
}
.infoBox {
  width: 588px;
  height: 560px;
  border: 1px solid #e6eefa;
}
.infoHeadBox {
  height: 56px;
  line-height: 56px;
  border-bottom: 1px solid #e6eefa;
}
.infoHeadLeft {
  width: 120px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.paddingleft20 {
  padding: 0 20px;
}
.margintop40 {
  margin-top: 40px;
}
.fangjiaBox {
  height: 130px;
  width: 120px;
  border: 1px solid #e6eefa;
}
.fangjiaBoxHead {
  height: 40px;
  line-height: 40px;
  background: #e7eef4;
  border-bottom: 1px solid #e6eefa;
  text-align: center;
}
.fangjiaBoxPrice {
  height: 90px;
  line-height: 90px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ee4d52;
}
.colorone {
  color: #256def;
}
</style>>

